import React from 'react';
import '../assets/styles/layout.scss';


export default function LoadingScreen () {
    return(
        <div className="bgLoading">
            <h1>One moment while we fetch your content!</h1>
        </div>
    );
}

